import React from "react";
import { Link } from "react-router-dom";

import "./BurgerMenu.scss";

const BurgerMenu = ({ user, city, component }) => {
  const link = (test, title, to, icon) => {
    return (
      <Link className={component === test ? "menu-item-selected" : ""} to={to}>
        <li>
          <i className={icon}></i>
          <p>{title}</p>
        </li>
      </Link>
    );
  };
  return (
    <nav role="navigation">
      <div id="burgerToggle">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>

        {/* ---------- Menu Begin  ------- */}
        <ul id="menu">
          {user && (
            <>
              {link("globalcalendar", "Global Calendar", "/globalcalendar")}
              {link("cities", "Cities", "/cities/")}
              {city && (
                <>
                  {link(
                    "coursecalendar",
                    "Course Calendar",
                    `/${city}/coursecalendar/`,
                  )}
                  {link(
                    "upcomingclass",
                    "Upcoming Classes",
                    `/${city}/upcomingclass/`
                  )}
                </>
              )}
              {city && user.role === "Administrator" && (
                <>
                  {link(
                    "courses",
                    "Courses",
                    `/${city}/courses/`
                  )}
                  {link(
                    "newcourse",
                    "New Course",
                    `/${city}/newcourse/`
                  )}
                  {link(
                    "newclass",
                    "New Class",
                    `/${city}/newclass/`
                  )}
                </>
              )}
              {user &&
                user.role === "Administrator" &&
                link("users", "Users", "/user/")}
              {user && link("account", "Account", "/account/")}
            </>
          )}
        </ul>
        {/* ---------------- menu-end --------------- */}
      </div>
    </nav>
  );
};

export default BurgerMenu;
