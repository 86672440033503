import React from "react";
import "./Loading.scss";

const Loading2 = ({ loaded }) => {
  return (
    <div role="region" aria-live="polite" className="loading-container">
      {loaded ? (
        <p className="nothing">Nothing to show</p>
      ) : (
        <p className="loading">Loading . . .</p>
      )}
    </div>
  );
};

export default Loading2;
