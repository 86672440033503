import React, { useContext } from "react";
import { Link } from "react-router-dom";

import UserContext from "../../context/UserContext";
import "./Header.scss";
import "./BurgerMenu.scss";
import logo from "../../images/cyf_brand.png";

import BurgerMenu from "./BurgerMenu.jsx";

const Header = ({ city, component }) => {
  const user = useContext(UserContext);

  const link = (test, title, to, icon) => {
    return (
      <Link
        className={
          component === test ? "header-nav header-selected" : "header-nav"
        }
        to={to}
      >
        <i className={icon}></i>
        <p>{title}</p>
      </Link>
    );
  };
  return (
    <div className="header">
      <div className="header-top-container">
        <Link className="header-mobile-logo" to="/">
          <img
            src={logo}
            alt="Code Your Future Logo"
            className="header-logo"
          ></img>
        </Link>
        <div className="header-burger-container">
          {user && <BurgerMenu user={user} city={city} component={component} />}
        </div>
      </div>

      <div className="header-down-container">
        <Link className="home-link header-logo" to="/">
          <img
            src={logo}
            alt="Code Your Future Logo"
            className="header-logo"
          ></img>
        </Link>
        <nav
          className="header-nav-container animate__animated animate__fadeIn"
          role="navigation"
          aria-label="Main header navigation"
        >
          {user && (
            <>
              <div className="user-section-navbar">
                {link("cities", "Cities", "/cities/")}
                {city && (
                  <>
                    {link(
                      "coursecalendar",
                      "Course Calendar",
                      `/${city}/coursecalendar/`
                    )}
                    {link(
                      "upcomingclass",
                      "Upcoming Classes",
                      `/${city}/upcomingclass/`
                    )}
                  </>
                )}
                {user.role === "Administrator" &&
                  (city ? (
                    <>
                      {link("courses", "Courses", `/${city}/courses/`)}
                      {link("newcourse", "New Course", `/${city}/newcourse/`)}
                      {link("newclass", "New Class", `/${city}/newclass/`)}
                    </>
                  ) : (
                    link("globalcalendar", "Global Calendar", "/globalcalendar")
                  ))}
              </div>
              <div className="admin-section-navbar">
                {user.role === "Administrator" &&
                  link("user", "Users", "/user/")}
                {user && link("account", "Account", "/account/")}
              </div>
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Header;
