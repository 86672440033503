import axios from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ClassCard from "../../components/ClassCard/ClassCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";

import "./UpcomingClass.scss";

dayjs.extend(isSameOrAfter);

const UpcomingClass = ({ city, component }) => {
  const [upcomingCourseClasses, setUpcomingCourseClasses] = useState(null);

  const sortAndAddWeekNumber = (classes) => {
    let counter = 0;
    if (classes[0] && !classes[0].weekNumber) {
      classes = classes
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
        .map((c) => {
          c.weekNumber = c.scheduleType === "Holiday" ? "-" : ++counter;
          return c;
        });
    }
    return classes;
  };

  const getUpcomingClass = useCallback(async () => {
    try {
      const {
        data: { data: courses },
      } = await axios.get(`/courses/${city}`);

      const getAllUpcomingClasses = courses
        .flatMap((course) => {
          const sortedClasses = sortAndAddWeekNumber(course.classes);
          const upcomingClasses = sortedClasses
            .filter(
              (class_) =>
                class_.scheduleType !== "Holiday" &&
                dayjs(class_.date).isSameOrAfter(dayjs(), "day")
            )
            .slice(0, 4);

          return upcomingClasses.map((class_) => ({
            ...class_,
            intakeName: course.intakeName,
          }));
        })
        .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

      setUpcomingCourseClasses(getAllUpcomingClasses);
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  }, [city]);

  useEffect(() => {
    getUpcomingClass();
  }, [getUpcomingClass]);

  return (
    <div>
      <Header city={city} component={component} />

      {upcomingCourseClasses && upcomingCourseClasses.length > 0 && (
        <div className="page-main-header-title">
          <p>{city}</p>
          <i className="fas fa-chevron-right"></i>
          <p className="title-text">Upcoming Classes</p>
        </div>
      )}
      <div className="upcoming-class-container">
        {upcomingCourseClasses && upcomingCourseClasses?.length > 0 ? (
          upcomingCourseClasses.map((Class, idx) => (
            <div className="card-title" key={idx} data-testid={`class-${idx}`}>
              <div className="content">
                <p className="city-name">{city}</p>
                <i className="fas fa-chevron-right"></i>
                <p className="intake-name">{Class.intakeName}</p>{" "}
              </div>

              <ClassCard
                key={Class._id}
                city={city}
                component={component}
                Class={Class}
                WeekNumber={Class.weekNumber}
              />
            </div>
          ))
        ) : (
          <div className="page-title">
            <p>{city}</p> <i className="fas fa-chevron-right"></i>
            <p>Upcoming Classes</p>
            <Loading2 loaded={upcomingCourseClasses !== null} />
          </div>
        )}
      </div>

      <p className="explanatory-text">
        For more classes, visit the{" "}
        <Link to={`/${city}/coursecalendar/`}>Course Calendar</Link> and select
        your Intake.
      </p>

      <Footer />
    </div>
  );
};

export default UpcomingClass;
